<template>
    <div class="flex flex-col gap-2">
        <slot></slot>
        <div class="p-4 text-white font-sans border flex items-center transition-all duration-300" :class="{
            'border-white border-2 shadow-[0_0_10px_rgba(255,255,255,0.7)] animate-pulse': isPlaying,
            'bg-[#280000] border-red-950/95': !isPlaying && (setInformation?.metadata?.staff_recommended || testMode === 'staff_recommended'),
            'bg-[#001a00] border-lime-950/95': !isPlaying && (setInformation?.metadata?.promoted || testMode === 'promoted'),
            'bg-black border-white/25': !isPlaying && ((!setInformation?.metadata?.staff_recommended && !setInformation?.metadata?.promoted && !testMode) || testMode === 'default')
        }">

            <div v-if="!setInformation" class="w-full animate-pulse flex justify-between items-center">
                <div class="space-y-2 flex-grow">
                    <div class="h-6 bg-white/20 rounded w-3/4"></div>
                    <div class="h-4 bg-white/20 rounded w-1/2"></div>
                    <div class="h-4 bg-white/20 rounded w-2/3"></div>
                </div>
                <div class="flex items-center space-x-3">
                    <div class="h-6 w-12 bg-white/20 rounded"></div>
                    <div class="h-10 w-10 bg-white/20 rounded-full"></div>
                </div>
            </div>
            <template v-else>
                <div class="flex justify-between items-center w-full">
                    <NuxtLink :to="demo ? null : `/stream/${setInformation.shareable_id}`"
                        class="space-y-2 flex-grow min-w-0  max-w-5xl">
                        <div class="flex items-center space-x-2">
                            <UserIcon v-if="artistCount <= 1" class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <UserGroupIcon v-else class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <h2 class="text-xl font-bold truncate">
                                <template v-for="(artist, index) in setInformation.metadata.artist" :key="index">
                                    <QueryableHref :use-slug="true" :trailing-text="index < artistCount - 1 ? ', ' : ''"
                                        type="artist" :query="artist" :allow-hover="false">
                                        <span class="inline hover:underline transition-colors duration-300 ease-in-out">
                                            {{ artist }}
                                        </span>
                                    </QueryableHref>
                                </template>
                            </h2>
                        </div>
                        <div v-if="hasEventInfo"
                            class="flex flex-col md:flex-row md:items-center md:space-x-2 text-sm truncate">
                            <template v-if="setInformation.metadata.festival">
                                <QueryableHref :query="setInformation.metadata.festival" type="festival">
                                    <span class="hover:text-white/75 transition-colors duration-300 ease-in-out">
                                        {{ setInformation.metadata.festival }}
                                    </span>
                                </QueryableHref>
                            </template>
                            <template
                                v-if="setInformation.metadata.festival && hasPromoters && !isMobile">
                                <span class="text-white/50">|</span>
                            </template>
                            <template v-if="hasPromoters">
                                <div class="flex items-center space-x-1">
                                    <QueryableHref v-for="(promoter, index) in setInformation.metadata.promoters" :key="index"
                                        :query="promoter" type="promoter" :use-slug="true">
                                        <span class="hover:text-white/75 transition-colors duration-300 ease-in-out">
                                            {{ promoter }}{{ index < setInformation.metadata.promoters.length - 1 ? ',' : '' }}
                                        </span>
                                    </QueryableHref>
                                </div>
                            </template>
                        </div>
                        <div class="flex items-center space-x-2 text-sm text-white/70 truncate">
                            <template v-if="setInformation.metadata.date">
                                <CalendarIcon class="h-4 w-4 flex-shrink-0" />
                                <span>{{ returnHumanizedDate(setInformation.metadata.date) }}</span>
                            </template>
                            <template
                                v-if="setInformation.metadata.date && setInformation.metadata.location && !isMobile">
                                <span class="text-white/30">•</span>
                            </template>
                            <template v-if="setInformation.metadata.location && !isMobile">
                                <MapPinIcon class="h-4 w-4 flex-shrink-0" />
                                <span>{{ setInformation.metadata.location }}</span>
                            </template>
                            <template v-if="setInformation.track_ids_available">
                                <span class="text-white/30">•</span>
                                <EyeIcon class="h-4 w-4 flex-shrink-0" />
                                <span>Track IDs</span>
                            </template>
                        </div>
                        <template v-if="setInformation.metadata.location && isMobile" class="flex gap-2">
                            <span class="flex items-center gap-2 text-sm opacity-50 truncate">
                                {{ humanDuration(setInformation.metadata.length) }}
                                <span v-if="setInformation.metadata.location">•</span>
                                <span>{{ setInformation.metadata.location }}</span>
                            </span>
                        </template>
                    </NuxtLink>

                    <div class="flex flex-col gap-2 items-end">
                        <div class="flex items-center gap-3">
                            <span class="text-md text-white/50 text-right hidden md:block">
                                {{ humanDuration(setInformation.metadata.length) }}
                            </span>
                            <LikeButton :set-information="setInformation" v-if="store.isAuthenticated" />
                            <button @click="togglePlayback"
                                class="rounded-full p-2 transition-colors duration-300 bg-white"
                                :class="{ 'bg-white hover:bg-white/90': isPlaying, 'bg-white hover:bg-white/90': !isPlaying }">
                                <PlayIcon v-if="!isPlaying" class="h-6 w-6 text-black" />
                                <PauseIcon v-else class="h-6 w-6 text-black" />
                            </button>
                            <span
                                class="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-md font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20 mr-2"
                                v-if="!setInformation.public">
                                Private
                            </span>
                            <NuxtLink v-if="setInformation.uploader.is_owner"
                                :to="`/account/edit/set/${setInformation.id}/metadata`"
                                class="ml-4 hidden md:inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-md font-medium text-gray-500 ring-1 ring-inset ring-gray-400/20 mr-2 hover:bg-gray-400/25 transition-colors duration-300 ">
                                <span class="hover:text-white inline-flex gap-1 items-center  ">
                                    <PencilIcon class="h-4 w-4" />
                                    <span class="ml-2 ">Edit</span>
                                </span>
                            </NuxtLink>
                        </div>
                        <span
                            class="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-sm font-medium text-red-500 ring-1 ring-inset ring-yellow-400/20 mr-2"
                            v-if="setInformation.metadata.staff_recommended || testMode === 'staff_recommended'">
                            <StarIcon class="h-4 w-4 text-red-500" />
                            <span class="ml-2">Staff Recommended</span>
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { UserGroupIcon, UserIcon, CalendarIcon, MapPinIcon, PlayIcon, PauseIcon, StarIcon, PencilIcon, HeartIcon, EyeIcon } from '@heroicons/vue/24/solid'
import { HeartIcon as HeartIconOutline } from '@heroicons/vue/24/outline'
import { returnHumanizedDate } from '~/utils/streamSeoDescription';
import { useAudioPlayerStore, useStore } from '~/store'

let audioPlayerStore = useAudioPlayerStore()

const quickSettingsToggle = ref(false)
const { isMobile } = useDevice();

let store = useStore()
const props = defineProps({
    setInformation: {
        type: Object,
        default: null
    },
    demo: {
        type: Boolean,
        default: false
    },
    testMode: {
        type: String,
        default: '',
        validator: (value) => ['', 'staff_recommended', 'promoted', 'default'].includes(value)
    },
})

const artistCount = computed(() => props.setInformation?.metadata.artist?.length ?? 0);
const isPlaying = computed(() =>
    audioPlayerStore.currentTrack?.id === props.setInformation?.id && audioPlayerStore.playerState === 'playing'
);

const hasEventInfo = computed(() =>
    props.setInformation?.metadata.festival || hasPromoters.value
);

const hasPromoters = computed(() => 
    props.setInformation?.metadata.promoters && props.setInformation.metadata.promoters.length > 0
);

function humanDuration(seconds) {
    if (!seconds) return '00:00';
    const pad = (num) => String(num).padStart(2, '0');
    const roundedSeconds = Math.round(seconds);
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds % 3600) / 60);
    const secs = roundedSeconds % 60;

    if (hours > 0) {
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    } else {
        return `${pad(minutes)}:${pad(secs)}`;
    }
}

function playTrack() {
    if (!props.setInformation) return;
    audioPlayerStore.playTrack({
        id: props.setInformation.id,
        streamable_url: props.setInformation.streamable_url,
        download_url: props.setInformation.download_url,
        metadata: props.setInformation.metadata,
        trackIds: props.setInformation.track_ids,
        trackIdsAvailable: props.setInformation.track_ids_available
    })
}

function togglePlayback() {
    if (props.demo || !props.setInformation) return;

    if (audioPlayerStore.currentTrack?.id === props.setInformation.id) {
        if (audioPlayerStore.playerState === 'playing') {
            audioPlayerStore.pause();
        } else {
            audioPlayerStore.play();
        }
    } else {
        playTrack();
    }
}

const artistBackground = computed(() => {
    if (props.setInformation?.metadata?.artist?.length == 1) {
        const artistName = props.setInformation.metadata.artist[0];
        return props.setInformation?.metadata?.additional_data?.artist_background_pictures?.[artistName] || null;
    }
    return null;
})
</script>

<style scoped>
@keyframes glowing {
    0% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); }
    50% { box-shadow: 0 0 20px rgba(255, 255, 255, 0.8); }
    100% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); }
}

.animate-pulse {
    animation: glowing 4s infinite;
}
</style>